.option-page-image {
  background-size: cover;
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.option-page-wrapper {
  position: absolute;
  width: 100%;
  top: 0;
}

.option-wrapper {
  width: 300px;
  padding: 15px 10px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.down-arrow {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

.title {
  font-size: 24px;
}

.option {
  font-size: 20px;
  align-items: center;
}

.flag-img,
.floor-img {
  margin-right: 6px;
  width: 30px;
  height: 30px;
}

.checkbox {
  border: 1px solid;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  margin-left: 16px;
  border-radius: 50%;
}

.go-btn {
  width: 200px;
  padding: 15px 10px;
  border-radius: 30px;
  margin-bottom: 15px;
  font-size: 24px;
  margin-top: 50px;
  background: rgb(246, 246, 248);
}


