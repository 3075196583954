.landing-wrapper {
  background-size: cover;
}

.landing-text {
  margin-left: auto;
  padding: 6rem;
  > div {
    color: #fff;
    font-weight: 600;
    font-size: 3.5rem;
  }
}

.touch-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 6rem;
  .outline {
    width: fit-content;
    border: 1px solid #fff;
    padding: 3px;
    border-radius: 40px;
  }

  .touch-btn {
    background: #fff;
    width: fit-content;
    border-radius: 40px;
    padding: 20px 80px;
    opacity: 0.8;
    font-size: 28px;
  }
}

.date-wrapper {
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  bottom: 3rem;
  left: 6rem;
  height: 70px;
}

.date-content {
  background: #4884D5;
  color: #fff;
  font-size: 20px;
  padding: 10px 18px;
}

.time-content {
  background: #fff;
  font-size: 18px;
  padding: 10px 18px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
