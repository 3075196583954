#root {
  width: 100%;
  height: 100vh;
  /* background-color: #fff; */

}

@font-face { font-family: Ganess Regular; src: url(./assets/ganesh_font.otf) format('otf'); }

.main-wrapper {
  width: 100%;
  height: 100vh;
  font-family: Ganess Regular;
}

.d-flex {
  display: flex;

}

.flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-white {
  color: #fff;
}

.bg-white {
  background: #fff;
}