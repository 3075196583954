.floor-title {
  font-size: 34px;
  margin-left: 3rem;
  color: #0199cb;
}

.exit-btn {
  width: 200px;
  padding: 15px 10px;
  border-radius: 30px;
  margin-bottom: 15px;
  font-size: 24px;
  margin-top: 50px;
  background: rgb(246, 246, 248);
  color: #333;
}

.map-image {
  height: calc(100vh - 64px);
  width: calc(100vw - 50%);
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.image-outer-wrapper  {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
/* width:auto */
}

.right-container{
  display:flex;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
}
.logo-section{
  padding-top: 26px;

  display:flex;
  align-items: center;
  flex-direction: column;
}

.language-options {
  /* margin-left: auto;
  margin-right: 20px; */
  gap: 20px;
  align-items: center;

  .language-option-wrapper {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px 22px;
    border: 1px solid;
    border-radius: 18px;
    font-size: 20px;
    width: 90px;

    .language-flag-img {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
  }
}