.footer-wrapper {
  height: 60px;
  background: #004680;
  padding: 0 40px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
}
.notice {
  color: #fff;
  background-color: #004680;
  padding: 20px;
  position: absolute;
  z-index: 1;
  left: 0;
  height: 40px;
  bottom: 0;
  width: 130px;
  border-top-right-radius: 30px;
  font-size: 26px;
  text-align: center;
}

.marquee-text {
  margin: 0 20px;
  width: fit-content;
}
